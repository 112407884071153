import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../../components/SEO';
import Header from '../../../components/Header';
import ContentRow from '../../../components/ContentRow';
import { commonHeaderColor } from '../../../util';
import { FindSales as FindSalesIcon, BuyOnline as BuyOnlineIcon } from '../../../images/svg-icons';


const Page = ({ data }) => (
  <>
    <SEO
      title="Neosurf ausgeben – anonyme Möglichkeit, online zu bezahlen"
      description="Neosurf-Gutscheine werden von tausenden Händlern weltweit akzeptiert."
      alternateLanguagePages={[
        {
          path: 'buy-neosurf/spend-neosurf',
          language: 'en'
        },
        {
          path: 'acheter-neosurf/spend-neosurf',
          language: 'fr'
        },
        {
          path: 'buy-neosurf/spend-neosurf',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={commonHeaderColor} />
    <ContentRow backgroundColor={commonHeaderColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://bit.ly/2AlU6Ch">
            <Img fluid={data.myneosurf.childImageSharp.fluid} alt="MyNeoSurf Logo" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="smaller h3 smaller">Neosurf ausgeben</h1>
            <p className="textColorDark">Verwenden Sie Ihren Neosurf-Gutschein – Wechseln Sie zu Krypto!</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <ContentRow padV>
      <p className="textAlignCenter"><FindSalesIcon width="100" /></p>
      <p className="textColorDark textAlignCenter"><span className="sectionMarker">1</span> Find your nest casino platform</p>
      <div className="grid c-2">
        <div className="textAlignCenter">
          <p><span className="sectionMarker">2</span></p>
          <p className="textColorDark"><strong>Select Neosurf</strong> during checkout</p>
        </div>
        <div className="textAlignCenter">
          <p><span className="sectionMarker">3</span></p>
          <p className="textColorDark"><strong>Enter your pincode</strong> to pay</p>
        </div>
      </div>
      <p className="textAlignCenter"><BuyOnlineIcon width="100" /></p>
      <p className="textColorDark textAlignCenter">Simply deposit with crypto to get started</p>
      <p className="textAlignCenter"><a className="button">Make crypto deposit</a></p>
    </ContentRow>
    <ContentRow padV>
      <p className="textColorDark">Haben Sie bereits einen Neosurf-Gutschein und sind bereit, ihn zu verwenden? Neosurf-Gutscheine werden von tausenden Händlern weltweit akzeptiert und bieten eine sichere und anonyme Möglichkeit, online zu bezahlen. Egal, ob Sie ein Gaming-Konto aufladen oder eine andere Online-Transaktion durchführen, die Verwendung von Neosurf ist so einfach wie das Eingeben des einzigartigen Codes, der mit dem Gutschein geliefert wird, an der Kasse.</p>
      <p className="textColorDark">Neosurf-Gutscheine sind besonders bei Gamern und Unterhaltungsfans beliebt, weshalb viele Online-Gaming-Plattformen Neosurf akzeptieren. Sie ermöglichen es Nutzern, ihre Konten schnell und einfach aufzuladen, ohne dass eine Kreditkarte oder ein Bankkonto erforderlich ist. Gehen Sie einfach zum Zahlungsbereich, wählen Sie Neosurf als Zahlungsmethode aus und geben Sie Ihren Code ein. Die Mittel werden sofort angewendet, sodass Sie mehr Zeit haben, Ihre Lieblingsspiele zu genießen.</p>
      <p className="textColorDark">Wenn Sie nach noch mehr Flexibilität suchen, können Sie Bitcoin erkunden. Es handelt sich um eine weltweit akzeptierte digitale Währung und eine fantastische Alternative für Nutzer, die mehr Vielseitigkeit bei ihren Online-Zahlungen wünschen. Besonders auf Plattformen wie LuckyStar.io können Sie mit Bitcoin sofort und sicher Gelder einzahlen. So nutzen Sie Bitcoin, wenn Sie diese Option in Betracht ziehen:</p>
      <ol>
        <li>
          <p className="textColorDark">Ein Bitcoin-Wallet einrichten - Ihr Wallet ist der Ort, an dem Sie Ihre Kryptowährung, einschließlich Bitcoin, speichern. Es gibt verschiedene Wallet-Optionen wie Online-Plattformen, Apps und sogar Hardware-Wallets, die die höchste Sicherheit bieten.</p>
        </li>
        <li>
          <p className="textColorDark">Bitcoin kaufen - Kaufen Sie Bitcoin bei vertrauenswürdigen Börsen mit Ihrer bevorzugten Zahlungsmethode.</p>
        </li>
        <li>
          <p className="textColorDark">Bitcoin ausgeben - Nutzen Sie Bitcoin, um Zahlungen auf Plattformen zu tätigen, die Kryptowährungen akzeptieren, wie Online-Casinos, Einkaufsseiten und mehr.</p>
        </li>
      </ol>
      <p className="textColorDark">Bitcoin ist auch eine ideale Lösung, wenn Sie Schwierigkeiten haben, Neosurf-Gutscheine in Ihrer Region zu finden, oder wenn Sie nach einer Zahlungsmethode suchen, die noch universeller akzeptiert wird. Mit Bitcoin können Sie regionale Einschränkungen leicht umgehen und die Vorteile von schnellen, sicheren Transaktionen genießen, egal wo auf der Welt Sie sich befinden.</p>
      <p className="textColorDark">Für diejenigen, die bereits einen Neosurf-Gutschein besitzen, bleibt dies eine großartige Zahlungsmethode für bestimmte Plattformen. Wenn Sie jedoch eine digitale Lösung erkunden möchten, ist Bitcoin die beste Wahl. Es bietet alle Datenschutz- und Sicherheitsvorteile, die Sie von Neosurf-Gutscheinen gewohnt sind, und darüber hinaus zusätzliche Funktionen wie globale Zugänglichkeit.</p>
      <p className="textColorDark">Ob Sie Ihren Neosurf-Gutschein ausgeben oder zu Bitcoin wechseln möchten – es gibt viele Optionen. Entdecken Sie noch heute die Vorteile beider Methoden und erfahren Sie, wie einfach und sicher Online-Zahlungen sein können. Und wenn Sie mehr über Bitcoin-Zahlungen erfahren möchten, besuchen Sie LuckyStar.io.</p>
    </ContentRow>
  </>
)

export default Page;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    myneosurf: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "buy_neosurf/hero.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    find_icon: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "buy_neosurf/find_sales_outlets.svg"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
